import { getServerInfoAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { Environment } from "@vatsim-vnas/js-libs/models/vnas";
import React, { useEffect, useState } from "react";
import * as S from "src/styles/Styles";

function Status({ environment }: { environment: Environment }) {
  const [color, setColor] = useState("red");

  useEffect(() => {
    (async () => {
      const res = await getServerInfoAsync(environment.apiBaseUrl);
      if (res.ok) {
        setColor("green");
      } else {
        setColor("red");
      }
    })();
  }, []);

  return (
    <S.Status>
      <S.StatusIndicator $color={color} />
      <S.StatusLabel>{environment.name}</S.StatusLabel>
    </S.Status>
  );
}

export default Status;
