import { faBook, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Changelog, Slideshow } from "src/components";
import { PUBLIC_URL } from "src/constants";
import * as S from "src/styles/Styles";

function DataAdmin() {
  return (
    <>
      <S.Row>
        <S.Card>
          <S.CardTitle>
            <S.CardTitleImage src="/img/logo.png" /> Data Admin Website
          </S.CardTitle>
          <S.CardBody>
            <p>
              The Data Admin website serves as the central place to edit and configure data used by vNAS systems. It is
              available to all VATUSA ATMs, DATMs, and FEs, though additional users can be whitelisted by ARTCC
              management.
            </p>
            <p>
              The Data Admin website is available on any web browser, though is optimized for larger (non-mobile)
              displays.
            </p>
          </S.CardBody>
        </S.Card>
        <S.Col>
          <S.ButtonLink $type="success" href="https://data-admin.vnas.vatsim.net">
            <FontAwesomeIcon icon={faRocket} /> <span>Launch</span>
          </S.ButtonLink>

          <S.ButtonLink $type="primary" href="https://data-admin.vnas.vatsim.net/docs">
            <FontAwesomeIcon icon={faBook} />
            <span>Documentation</span>
          </S.ButtonLink>
          <Slideshow
            imageUrls={[
              "/img/data-admin/data-admin.png",
              "/img/data-admin/data-admin-facility.png",
              "/img/data-admin/data-admin-video-maps.png",
              "/img/data-admin/data-admin-foreign.png",
            ]}
          />
        </S.Col>
      </S.Row>
      <S.Row>
        <Changelog changelogUrl={`${PUBLIC_URL}/changelogs/data-admin.json`} />
      </S.Row>
    </>
  );
}
export default DataAdmin;
