import React, { useEffect } from "react";

interface RedirectProps {
  url: string;
}

function Redirect({ url }: Readonly<RedirectProps>) {
  useEffect(() => {
    window.location.replace(url);
  }, []);

  return (
    <p style={{ textAlign: "center" }}>
      Redirecting... <br /> Not working? Click <a href={url}>here</a>.
    </p>
  );
}

export default Redirect;
