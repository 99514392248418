import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { GlobalStyle } from "src/styles";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <GlobalStyle />
    <App />
    <ToastContainer position="top-right" toastStyle={{ color: "white", backgroundColor: "#202427" }} />
  </StrictMode>,
);
