import React from "react";
import { Changelog } from "src/components";
import { PUBLIC_URL } from "src/constants";
import * as S from "src/styles/Styles";

function Server() {
  return (
    <>
      <S.Row>
        <S.Card>
          <S.CardTitle>
            <S.CardTitleImage src="/img/logo.png" />
            vNAS Server
          </S.CardTitle>
          <S.CardBody>
            <p>
              The vNAS Server is the central backbone that services all vNAS clients including CRC, vStrips and vTDLS.
              The server allows for custom client-to-client functionality that would otherwise be impossible on VATSIM,
              such as shared flight strip bays. The server also serves as an intermediary between vNAS clients and
              VATSIM servers.
            </p>
          </S.CardBody>
        </S.Card>
      </S.Row>
      <S.Row>
        <Changelog changelogUrl={`${PUBLIC_URL}/changelogs/server.json`} />
      </S.Row>
    </>
  );
}
export default Server;
