import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { faBook, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Slideshow } from "src/components";
import * as S from "src/styles/Styles";

function AtcTrainer() {
  const onWindows = navigator.userAgent.includes("Windows");

  return (
    <S.Row>
      <S.Card>
        <S.CardTitle>
          <S.CardTitleImage src="/img/atctrainer/atctrainer-logo.png" /> ATCTrainer
        </S.CardTitle>
        <S.CardBody>
          <p>
            ATCTrainer and provides a realistic training simulation in the terminal, TRACON and en route environments.
            With an easy to use interface and simple commands compatible with any ATC client, ATCTrainer is simple to
            set up and easy to use. ATCTrainer supports VATSIM Velocity allowing for fast position updates for supported
            ATC clients.
          </p>
          <p>ATCTrainer is available on Windows and only accessible by VATUSA designated training staff.</p>
        </S.CardBody>
      </S.Card>
      <S.Col>
        {onWindows ? (
          <S.ButtonLink $type="success" href="https://atctrainer.collinkoldoff.dev/downloads">
            <FontAwesomeIcon icon={faDownload} /> <span>Download</span>
          </S.ButtonLink>
        ) : (
          <S.Button disabled $type="success">
            <FontAwesomeIcon icon={faWindows} /> <span>Available on Windows</span>
          </S.Button>
        )}
        <S.ButtonLink $type="primary" href="https://atctrainer.collinkoldoff.dev/docs">
          <FontAwesomeIcon icon={faBook} />
          <span>Documentation</span>
        </S.ButtonLink>
        <Slideshow imageUrls={["/img/atctrainer/atctrainer-main.png"]} />
      </S.Col>
    </S.Row>
  );
}
export default AtcTrainer;
