import React from "react";
import * as S from "src/styles/Styles";

const YEAR = new Date().getFullYear();

function Footer() {
  return <S.Footer>©️ {YEAR} Ross Alan Carlson - All rights reserved.</S.Footer>;
}

export default Footer;
