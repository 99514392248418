import "src/fonts/fonts.css";
import { createGlobalStyle } from "styled-components";
import "./imports.css";

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;  
        font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        background-color: #454d55;
        color: white;
    }

    a {
        color: white;
    }

    svg {
        font-size: 1.5em;
    }
`;

export default GlobalStyle;
