import React, { useEffect } from "react";

import { Navigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BOT_URL, DOMAIN, VATSIM_CLIENT_ID } from "src/constants";

const LOGIN_URL = `${DOMAIN}/login`;
const REDIRECT_URL = encodeURIComponent(LOGIN_URL);
const VATSIM_LOGIN_URL = `https://auth.vatsim.net/oauth/authorize?client_id=${VATSIM_CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code&scope=full_name`;

function Login() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const discordToken = searchParams.get("discord");

  const loginOnBot = async (localDiscordToken: string) => {
    const body = JSON.stringify({
      clientId: VATSIM_CLIENT_ID,
      code,
      redirectUrl: LOGIN_URL,
      discordToken: localDiscordToken,
    });
    const res = await fetch(`${BOT_URL}/login-hook`, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.ok) {
      toast.success("Successfully logged in");
    } else {
      toast.error(`Failed to log in: ${res.statusText}`);
    }
  };

  useEffect(() => {
    if (discordToken) {
      window.location.href = VATSIM_LOGIN_URL;
    } else if (code) {
      const localDiscordToken = sessionStorage.getItem("discord-token");
      if (!localDiscordToken) {
        toast.error("Failed to log in: Missing Discord token");
      } else {
        loginOnBot(localDiscordToken);
      }
    }
  }, [code, discordToken]);

  if (discordToken) {
    sessionStorage.setItem("discord-token", discordToken);
  }

  return <Navigate to="/" replace />;
}

export default Login;
