import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Header = styled.header`
  position: relative;
`;

export const HeaderBackgroundImage = styled(Link)<{ $backgroundUrl: string }>`
  width: 100%;
  height: 300px;
  background-image: url(${(p) => p.$backgroundUrl});
  background-position: center;
  background-size: cover;
  filter: blur(2px) brightness(55%);
  display: block;

  @media only screen and (max-width: 700px) {
    height: 150px;
  }
`;

export const HeaderContent = styled.div`
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  pointer-events: none;

  @media only screen and (max-width: 700px) {
    top: 75px;
  }
`;

export const HeaderLogo = styled.img`
  height: 150px;
  width: 150px;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const HeaderText = styled.span`
  font-size: 7em;
  font-family: "Montserrat";
  font-weight: 600;
  margin-left: 25px;
`;

export const NavBar = styled.nav`
  min-height: 50px;
  width: 100%;
  background-color: #343a40;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(-2px);
`;

export const NavItems = styled.div<{ $menuOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  font-size: 1.2em;
  width: 100%;
  transition: height 0.5s ease-in-out;
  @media only screen and (max-width: 700px) {
    height: 222px;
    flex-direction: column;
    gap: 0;
    overflow: hidden;
    height: ${(p) => !p.$menuOpen && "0"};
  }
`;

export const NavItem = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    text-align: center;
    padding: 5px;
  }
`;

export const NavItemSeparator = styled.span`
  height: 5px;
  width: 5px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;

  @media only screen and (max-width: 700px) {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #ffffff22;
  }
`;

export const NavMenu = styled.div`
  margin-left: calc(100% - 100px);
  background-color: inherit;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;

  @media only screen and (min-width: 700px) {
    display: none;
  }
`;

export const Content = styled.section`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (max-width: 700px) {
    flex-basis: 100%;
  }
`;

export const Card = styled.div`
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  background-color: #343a40;
  box-shadow:
    0 0 1px rgb(0 0 0 / 13%),
    0 1px 3px rgb(0 0 0 / 20%);
  flex-grow: 1;
  flex-basis: 0;
  min-width: min(500px, 100%);

  @media only screen and (max-width: 700px) {
    flex-basis: 100%;
  }
`;

export const CardTitle = styled.div`
  padding: 0.75rem 1.25rem;
  font-size: 1.75rem;
  font-weight: 400;
  border-bottom: 1px solid #ffffff22;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const CardTitleImage = styled.img`
  height: 50px;
  width: 50px;
`;

export const CardSubtitle = styled(CardTitle)`
  padding: 0.75rem 1.25rem;
  font-size: 1.2rem;
  font-weight: 400;
  border-bottom: 1px solid #ffffff22;
`;

export const CardBody = styled.div`
  padding: 1.25rem;
`;

const getTypeColor = (type: "primary" | "success" | "discord") => {
  switch (type) {
    case "success":
      return "#00bc8c";
    case "discord":
      return "#5865f2";
    case "primary":
    default:
      return "#335476";
  }
};

export const Button = styled.button<{ $type: "primary" | "success" }>`
  padding: 1rem;
  min-width: 200px;
  color: white;
  border: none;
  font-size: 1.2em;
  border-radius: 0.375rem;
  box-shadow:
    0 0 1px rgb(0 0 0 / 13%),
    0 1px 3px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: min-content;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(80%);
  }

  > span {
    margin-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
    filter: brightness(110%);
  }

  background-color: ${(p) => getTypeColor(p.$type)};
`;

export const ButtonLink = styled.a<{ $type: "primary" | "success" | "discord" }>`
  padding: 1rem;
  min-width: 200px;
  font-size: 1.2em;
  border-radius: 0.375rem;
  box-shadow:
    0 0 1px rgb(0 0 0 / 13%),
    0 1px 3px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: min-content;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(80%);
  }

  > span {
    margin-left: 10px;
  }

  background-color: ${(p) => getTypeColor(p.$type)};
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const StatusIndicator = styled.div<{ $color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${(p) => p.$color};
  border-radius: 50%;
`;

export const StatusLabel = styled.span`
  margin-left: 10px;
  font-weight: 500;
`;

export const ClientBox = styled(Link)`
  width: 300px;
  height: 300px;
  border-radius: 0.375rem;
  position: relative;
  overflow: hidden;
  font-size: 4.5em;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  text-align: center;
  box-shadow:
    0 0 1px rgb(0 0 0 / 13%),
    0 1px 3px rgb(0 0 0 / 20%);
  border: 5px solid #ffffff99;

  &:hover {
    font-size: 4.8em;
  }

  @media only screen and (max-width: 700px) {
    flex-basis: 100%;
  }
`;

export const ClientBoxBackgroundImage = styled.img<{ $url: string; $darken?: boolean }>`
  height: 100%;
  width: 100%;
  background-image: url(${(p) => p.$url});
  background-size: cover;
  background-position: center;
  transition: all 0.2s !important;
  filter: blur(0) brightness(${(p) => (p.$darken ? "45%" : "90%")});
  &:hover {
    filter: blur(5px) brightness(${(p) => (p.$darken ? "35%" : "85%")});
  }
`;

export const ClientBoxLabel = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  text-shadow: 2px 2px rgb(0 0 0 / 20%);
`;

export const Slideshow = styled.div`
  box-shadow:
    0 0 1px rgb(0 0 0 / 13%),
    0 1px 3px rgb(0 0 0 / 20%);
  border-radius: 0.375rem;
  width: 500px;
  height: 300px;
  position: relative;
  background: #ccc;
  border: 5px solid #ffffff99;

  @media only screen and (max-width: 700px) {
    width: auto;
  }
`;

export const Slide = styled.a<{ $backgroundImageUrl: string; $isActive: boolean }>`
  background-image: url(${(p) => p.$backgroundImageUrl});
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  display: block;
  animation-name: fade;
  animation-duration: 1s;
  cursor: zoom-in;

  ${(p) =>
    !p.$isActive &&
    css`
      display: none;
    `}

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`;

const SlideshowControl = styled.button`
  background-color: #00000088;
  cursor: pointer;
  position: absolute;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  user-select: none;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.6s ease;
  border: none;

  &:hover {
    background-color: #000000cc;
  }
`;

export const SlideshowNext = styled(SlideshowControl)`
  right: 0;
  border-radius: 3px 0 0 3px;
`;

export const SlideshowPrevious = styled(SlideshowControl)`
  border-radius: 0 3px 3px 0;
`;

export const SlideshowDotBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SlideDot = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  background-color: ${(p) => p.$isActive && "#ddd"};

  &:hover {
    background-color: #ddd;
  }
`;

export const ChangelogDate = styled.small`
  color: #ccc;
`;

export const Footer = styled.footer`
  padding: 16px;
  background-color: #343a40;
  text-align: center;
  box-sizing: border-box;
  margin-top: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
