import { Squeeze as Hamburger } from "hamburger-react";
import React, { useMemo, useState } from "react";
import * as S from "src/styles/Styles";

const BACKGROUND_URLS = [
  "/img/crc/asdex-header.png",
  "/img/crc/eram-header.png",
  "/img/crc/stars-header.png",
  "/img/crc/towercab1.png",
];

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const backgroundUrl = useMemo(() => BACKGROUND_URLS[Math.floor(Math.random() * BACKGROUND_URLS.length)], []);

  return (
    <S.Header>
      <S.HeaderBackgroundImage to="/" $backgroundUrl={backgroundUrl} />
      <S.HeaderContent>
        <S.HeaderLogo src="/img/logo.png" />
        <S.HeaderText>vNAS</S.HeaderText>
      </S.HeaderContent>
      <S.NavBar>
        <S.NavMenu>
          <Hamburger toggled={menuOpen} toggle={setMenuOpen} />
        </S.NavMenu>
        <S.NavItems $menuOpen={menuOpen}>
          <S.NavItem to="crc">CRC</S.NavItem>
          <S.NavItemSeparator />
          <S.NavItem to="server">vNAS Server</S.NavItem>
          <S.NavItemSeparator />
          <S.NavItem to="vstrips">vStrips</S.NavItem>
          <S.NavItemSeparator />
          <S.NavItem to="vtdls">vTDLS</S.NavItem>
          <S.NavItemSeparator />
          <S.NavItem to="data-admin">Data Admin</S.NavItem>
          <S.NavItemSeparator />
          <S.NavItem to="atctrainer">ATCTrainer</S.NavItem>
        </S.NavItems>
      </S.NavBar>
    </S.Header>
  );
}

export default Header;
