import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, Header } from "src/components";
import * as S from "src/styles/Styles";

function Main() {
  return (
    <S.Wrapper>
      <Header />
      <S.Content>
        <Outlet />
      </S.Content>
      <Footer />
    </S.Wrapper>
  );
}

export default Main;
