import React, { useEffect, useState } from "react";
import { ChangelogEntry } from "src/models";
import * as S from "src/styles/Styles";

function Changelog({ changelogUrl }: { changelogUrl: string }) {
  const [entries, setEntries] = useState<ChangelogEntry[]>([]);

  useEffect(() => {
    (async () => {
      const res = await fetch(changelogUrl);
      const json = await res.json();
      setEntries(json);
    })();
  }, []);

  return (
    <S.Card>
      <S.CardTitle>Changelog</S.CardTitle>
      <S.CardBody>
        {entries.map((e) => (
          <>
            <h1>
              {e.version} - <S.ChangelogDate>{e.date}</S.ChangelogDate>
            </h1>
            <ul>
              {e.notes.map((n) => (
                <li key={n}>{n}</li>
              ))}
            </ul>
          </>
        ))}
      </S.CardBody>
    </S.Card>
  );
}

export default Changelog;
