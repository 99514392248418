import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Main } from "src/modules";
import { AtcTrainer, Crc, DataAdmin, Home, Login, Redirect, Vstrips, Vtdls } from "src/pages";
import { AUTO_ATC_FEEDBACK_URL } from "./constants";
import Server from "./pages/Server";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route path="/crc" element={<Crc />} />
          <Route path="/server" element={<Server />} />
          <Route path="/vstrips" element={<Vstrips />} />
          <Route path="/vtdls" element={<Vtdls />} />
          <Route path="/data-admin" element={<DataAdmin />} />
          <Route path="/atctrainer" element={<AtcTrainer />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auto-atc-feedback" element={<Redirect url={AUTO_ATC_FEEDBACK_URL} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
